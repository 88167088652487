<template>
    <div class="container">
        <div class="abiturient-list mt-4">

            <div class="row mt-4">
                <p>{{ abitOrdersItemText }}</p>
            </div>

            <h4 style="text-align: center">Список студентов</h4>

            <!-- Edit Abiturient Data Modal -->
            <div class="modal fade" id="editAbiturientDataModal" tabindex="-1"
                 aria-labelledby="editAbiturientDataModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editAbiturientDataModalLabel">Изменение ОП</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">

                                <div class="form-group row mt-4">
                                    <label for="education_program" class="col-md-3 col-form-label">Выберите ОП</label>
                                    <div class="col-md-9">
                                        <select class="form-control form-select" name="education_program"
                                                id="education_program"
                                                @input="changeAbiturientEpd($event)">
                                            <option
                                                    v-for="(program, index) in [{id: 0, education_speciality_name: 'Выберите ОП'}, ...abiturientPrograms]"
                                                    :value="program.education_speciality_code"
                                                    :key="index">{{ program.education_speciality_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                        @click="putAbiturientData">
                                    Обновить
                                </button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                    Отменить
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->


            <!-- Delete Abiturient Data Modal -->
            <div class="modal fade" id="deleteAbiturientDataModal" tabindex="-1"
                 aria-labelledby="deleteAbiturientDataModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="deleteAbiturientDataModalLabel">Удаление студениа</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">
                                <p>Вы точно хотите удалить этого студента?</p>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                        @click="deleteAbiturientData">
                                    Удалить
                                </button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                    Отменить
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->


<!--            <div class="row mt-4">-->
<!--                <div class="col-md-6">-->
<!--                    <select class="form-control form-select" id="education_programs" @input="changeEducationProgram">-->
<!--                        <option v-for="(program, index) in [{id: 0, education_speciality_name: 'Все'}, ...abiturientPrograms]"-->
<!--                                :value="program.id"-->
<!--                                :key="index"-->
<!--                        >-->

<!--                            {{ program.education_speciality_name }} {{-->
<!--                            program.id != 0 ? ' - ' +-->
<!--                            program.language.native_name : ''-->
<!--                            }}-->
<!--                        </option>-->
<!--                    </select>-->

<!--                </div>-->
<!--            </div>-->

<!--          v-if="!isConfirmAbiturient"-->
          <div class="mt-3 mb-3" v-if="urlPreUniversity != 'null'">
                <span>Выбрать всех: </span>

                <input type="checkbox" class="big-checkbox" :checked="allCheck"
                       @change="changeAllAbiturientCheckStatus($event)">
            </div>

            <!-- table start -->
            <div class="table-responsive-md mt-4">
                <table class="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col" v-if="urlPreUniversity != 'null'">Статус</th>
<!--                      v-if="!isConfirmAbiturient"-->
                        <th scope="col">Баркод</th>
                        <th scope="col">ФИО</th>
                        <th scope="col">ИИН</th>
                        <th scope="col">Название ОП</th>
                        <th scope="col">Язык обучения</th>
                        <th scope="col" v-if="urlPreUniversity != 'null'">Удаление</th>
                        <!--            <th scope="col">Действия</th>-->
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(abiturient, index) of abiturientsList"
                        :key="index">

                        <th scope="row">{{ index + 1 }}</th>
                        <td v-if="urlPreUniversity != 'null'">
<!--                          v-if="!isConfirmAbiturient"-->
                            <input type="checkbox" class="big-checkbox" :checked="abiturient.status == 2 || abiturient.status == 1"
                                   @change="changeAbiturientCheckStatus(abiturient.barcode, $event)" :disabled="abiturient.status == 2">
                        </td>
                        <td>{{ abiturient.barcode }}</td>
                        <td>
                            <a :href="abiturient.url_bitrix">
                                {{ abiturient.last_name_udv }} {{ abiturient.first_name_udv }}
                                {{ abiturient.middle_name_udv }}
                            </a>
                        </td>
                        <td>{{ abiturient.abit_iin }}</td>
                        <td>{{ abiturient.education_program }}</td>
                        <td>{{ abiturient.study_language }}</td>
                        <td>
                          <div v-if="abiturient.status == 2">
                            <button type="button" class="btn btn-danger m-1" data-bs-toggle="modal"
                                    data-bs-target="#deleteAbiturientDataModal"
                                    @click="openDeleteAbiturientDataModal(abiturient.barcode)">
                              <i class="fa fa-trash-o" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div v-else>
                            <p>Студент не сохранен</p>
                          </div>

                        </td>


                        <!--            <td>-->
                        <!--              <a href="#" data-bs-toggle="modal" data-bs-target="#editAbiturientDataModal"-->
                        <!--                 @click="openEditAbiturientDataModal(abiturient.id)">Изменить ОП</a>-->
                        <!--              <br>-->
                        <!--              <a href="#" data-bs-toggle="modal" data-bs-target="#deleteAbiturientDataModal"-->
                        <!--                 @click="openDeleteAbiturientDataModal(abiturient.id)">Удалить студента</a>-->

                        <!--            </td>-->


                    </tr>
                    </tbody>
                </table>

            </div>
            <!-- table end -->
<!--            <div class="col-md-12 mt-4 mb-5 text-center" v-if="!isConfirmAbiturient">-->
<!--                <button type="button" class="btn btn-primary" :disabled="isConfirm"-->
<!--                        @click="postAbiturientOrdersItemStudents">-->
<!--                    {{ isConfirm ? 'Подтверждение...' : 'Потвердить' }}-->
<!--                </button>-->
<!--            </div>-->


          <div class="col-md-12 mt-4 mb-5 text-center" v-if="urlPreUniversity != 'null'">
<!--            v-if="!isConfirmAbiturient"-->
            <button type="button" class="btn btn-primary" :disabled="isConfirm"
                    @click="postAbiturientData" style="margin-right: 14px">
              {{ isConfirm ? 'Сохранение...' : 'Сохранить' }}
            </button>

            <button type="button" class="btn btn-primary" :disabled="isApprove"
                    @click="postStudentData">
              {{ isApprove ? 'Утверждение...' : 'Утвердить' }}
            </button>
          </div>

        </div>
    </div>
</template>


<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "AbiturientList",

        data() {
            return {
                urlAbitOrdersItemId: this.$route.query.abit_orders_item_id || 0,
                urlPaymentType: this.$route.query.payment_type || null,
                urlDiscount: this.$route.query.discount || 0,
                urlPreUniversity: this.$route.query.pre_university || null,
                deleteAbiturientDataBarcode: null,
                selectedEducationProgram: 0,
                isConfirm: false,
                isApprove: false,
                allCheck: false
            }
        },
        computed: {
            abitOrdersItemText() {
                let itemText = this.item_form.abiturientOrdersItem.find(i => i.id == this.urlAbitOrdersItemId)
                return itemText ? itemText.item_text : ''
            },

            abiturientsList() {
                if (this.selectedEducationProgram) {
                    const education_program = this.abiturientPrograms.find(i => i.id == this.selectedEducationProgram)

                    let education_speciality_code = education_program.education_speciality_code
                    education_speciality_code = education_speciality_code.replace('В', 'B')
                    education_speciality_code = education_speciality_code.replace('М', 'M')

                    let study_language = ''

                    if (education_program.language_id == 82) {
                        study_language = 'Казахский'
                    } else if (education_program.language_id == 137) {
                        study_language = 'Русский'
                    } else if (education_program.language_id == 39) {
                        study_language = 'Английский'
                    }

                    return this.abiturients.filter(abiturient => abiturient.bitrix_epd_code.trim() == education_speciality_code.trim() && abiturient.study_language == study_language)

                }
                return this.abiturients
            },

            ...mapState('abiturient', ['abiturientPrograms', 'abiturients', 'isConfirmAbiturient']),
            ...mapState('item', ['item_form']),
        },


        methods: {

            ...mapActions('abiturient', ['GET_ABITURIENT_PROGRAMS', 'GET_ABITURIENTS', 'POST_ABITURIENT_ORDERS_ITEM_STUDENTS',
                'UPDATE_ABITURIENT_DATA_STATUS', 'PUT_ABITURIENT_DATA', 'GET_ABITURIENTS_NEW', 'POST_ABITURIENT_DATA', 'POST_STUDENT_DATA', 'DELETE_ABITURIENT_DATA']),
            ...mapActions('item', ['GET_ABITURIENT_ORDERS_ITEM_DATA']),
            ...mapMutations('abiturient', ['SET_ABIT_ORDERS_ITEM_ID', 'SET_EDIT_ABITURIENT_ID', 'CHANGE_ABITURIENT_EPD', 'CHANGE_ABITURIENT_CHECKED_STATUS', 'CHANGE_ALL_ABITURIENT_CHECKED_STATUS']),
            async postAbiturientOrdersItemStudents() {
                this.isConfirm = true
                this.SET_ABIT_ORDERS_ITEM_ID(this.urlAbitOrdersItemId)
                let filteredArray = this.abiturients.filter(i => i.status == 1)

                let errStatus = 0
                const subarray = this.splitIntoSubarrays([...filteredArray], 50)

                try {
                    for (let item of subarray) {
                        let res = await this.POST_ABITURIENT_ORDERS_ITEM_STUDENTS(item)
                        if (res.success != true) {
                            errStatus = 1
                            this.$error({title: 'Потверждение', text: 'Произошла ошибка'})
                            break
                        }
                    }
                    if (!errStatus) {
                        this.$message({title: 'Потверждение', text: 'Успешно потверждено'})
                    }
                } catch (error) {
                    console.error(error)
                    this.$error({title: 'Потверждение', text: 'Произошла ошибка'})
                } finally {
                    this.isConfirm = false
                    await this.GET_ABITURIENTS({
                        payment_type: this.urlPaymentType,
                        discount: this.urlDiscount,
                        abit_orders_item_id: this.urlAbitOrdersItemId
                    })
                }


            },

          async postAbiturientData() {
            this.isConfirm = true
            this.SET_ABIT_ORDERS_ITEM_ID(this.urlAbitOrdersItemId)
            let filteredArray = this.abiturients.filter(i => i.status == 1)

            let errStatus = 0
            const subarray = this.splitIntoSubarrays([...filteredArray], 50)

            try {
              for (let item of subarray) {
                let res = await this.POST_ABITURIENT_DATA(item)
                if (res != true) {
                  errStatus = 1
                  this.$error({title: 'Потверждение', text: 'Произошла ошибка'})
                  break
                }
              }
              if (!errStatus) {
                this.$message({title: 'Потверждение', text: 'Успешно потверждено'})
              }
            } catch (error) {
              console.error(error)
              this.$error({title: 'Потверждение', text: 'Произошла ошибка'})
            } finally {
              this.isConfirm = false
              await this.GET_ABITURIENTS_NEW({
                abit_orders_item_id: this.urlAbitOrdersItemId
              })
            }


          },

          async postStudentData() {
            this.isApprove = true
            let form = {}
            form['abit_orders_item_id'] = this.urlAbitOrdersItemId
            console.log(form, 'submit form')

            let postData = await this.POST_STUDENT_DATA(form)
            if (postData) {

              await this.GET_ABITURIENTS_NEW({
                abit_orders_item_id: this.urlAbitOrdersItemId
              })
              this.$message({text: 'Студенты утверждены'})
            }
            this.isApprove = false
          },


            splitIntoSubarrays(array, subarraySize = 100) {
                let subarray = [];
                for (let i = 0; i < Math.ceil(array.length / subarraySize); i++) {
                    subarray[i] = array.slice((i * subarraySize), (i * subarraySize) + subarraySize);
                }
                return subarray
            },
            async putAbiturientData() {
                await this.PUT_ABITURIENT_DATA()
                await this.GET_ABITURIENTS(this.urlPaymentType, this.urlDiscount)
            },
            async openEditAbiturientDataModal(editAbiturientDataId) {
                await this.SET_EDIT_ABITURIENT_ID(editAbiturientDataId)
            },

            async openDeleteAbiturientDataModal(deleteAbiturientDataBarcode) {
                this.deleteAbiturientDataBarcode = deleteAbiturientDataBarcode
            },
            async deleteAbiturientData() {
              await this.DELETE_ABITURIENT_DATA(this.deleteAbiturientDataBarcode)
              await this.GET_ABITURIENTS_NEW({
                abit_orders_item_id: this.urlAbitOrdersItemId
              })
            },
            changeEducationProgram(e) {
                this.selectedEducationProgram = +e.target.value

            },
            changeAbiturientEpd(e, val = 'value') {
                const value = e.target[val]

                this.CHANGE_ABITURIENT_EPD({"epd": value})
            },

            changeAbiturientCheckStatus(barcode, e) {
                const value = e.target.checked
                const status = value ? 1 : 0

                this.CHANGE_ABITURIENT_CHECKED_STATUS({barcode, status})


            },
            changeAllAbiturientCheckStatus(e) {
                const value = e.target.checked
                this.allCheck = value

                const status = value ? 1 : 0
                this.CHANGE_ALL_ABITURIENT_CHECKED_STATUS(status)
            }

        },
        async mounted() {
            //await this.GET_ABITURIENT_PROGRAMS()
          console.log(this.urlPreUniversity, 'urlPreUniversity')

          if (this.urlPreUniversity === 'null') {
            await this.GET_ABITURIENTS({
              payment_type: this.urlPaymentType,
              discount: this.urlDiscount,
              abit_orders_item_id: this.urlAbitOrdersItemId,
              //pre_university: this.urlPreUniversity,
            })
          }
          else {
            await this.GET_ABITURIENTS_NEW({
              //payment_type: this.urlPaymentType,
              //discount: this.urlDiscount,
              abit_orders_item_id: this.urlAbitOrdersItemId,
              //pre_university: this.urlPreUniversity,
            })
          }

            await this.GET_ABITURIENT_ORDERS_ITEM_DATA()

        }
    }
</script>

<style scoped>
    .big-checkbox {
        width: 20px;
        height: 20px;
    }

</style>